<template>
  <div class="cursor-pointer">
    <q-input
      :value="formatedValue"
      :label="label"
      :outlined="outlined"
      :dense="dense"
      class="no-pointer-events"
    />

    <q-popup-proxy :cover="false" :offset="[0, 10]" max-width="320px">
      <q-date :value="value" :range="range" @input="onInput" />
    </q-popup-proxy>
  </div>
</template>

<script>
import { date } from 'quasar';

export default {
  props: {
    value: {
      type: [String, Object],
    },
    label: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    range: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formatedValue() {
      if (!this.value) {
        return '';
      }

      if (typeof this.value === 'string') {
        return this.value;
      }

      const fromDate = date.extractDate(this.value.from, 'YYYY/MM/DD');
      const toDate = date.extractDate(this.value.to, 'YYYY/MM/DD');

      return [
        fromDate.toLocaleString('en', {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        }),

        toDate.toLocaleString('en', {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        }),
      ].join(' - ');
    },
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>
